var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "main" }, [
    _c(
      "div",
      { staticClass: "crumbs" },
      [
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _c("el-breadcrumb-item", [
              _c("i", { staticClass: "el-icon-lx-global" }),
              _vm._v(" " + _vm._s(_vm.$t("i18n.breadcrumb")))
            ])
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("span", [_vm._v(_vm._s(_vm.$t("i18n.tips")))]),
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function($event) {
                _vm.$i18n.locale = _vm.$i18n.locale === "zh" ? "en" : "zh"
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("i18n.btn")))]
        ),
        _c("div", { staticClass: "list" }, [
          _c("h2", [_vm._v(_vm._s(_vm.$t("i18n.title1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$t("i18n.p1")))]),
          _c("p", [_vm._v(_vm._s(_vm.$t("i18n.p2")))]),
          _c("p", [_vm._v(_vm._s(_vm.$t("i18n.p3")))])
        ]),
        _c("h2", [_vm._v(_vm._s(_vm.$t("i18n.title2")))]),
        _c(
          "div",
          [
            _c("i18n", { attrs: { path: "i18n.info", tag: "p" } }, [
              _c(
                "a",
                {
                  attrs: {
                    place: "action",
                    href: "https://element.eleme.cn/2.0/#/zh-CN/component/i18n"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("i18n.value")))]
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }